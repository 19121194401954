import React from 'react'
import { ProgressIndicator } from '@veneer/core'
import { Container } from './styles'
import testid from '../../utils/testeId'
import LoaderProps from './type'

export const Loader: React.FC<LoaderProps> = ({ fullScreen }) => (
  <Container fullScreen={fullScreen}>
    <ProgressIndicator data-testid={testid('loader')} />
  </Container>
)
