import styled from 'styled-components'

interface ContainerProps {
  fullScreen: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.fullScreen
      ? `position: absolute;
       height: 100%;
       width: 100%;`
      : `flex: 1;
       height: 100px;
  `}
`

export default Container
