/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { IconXCircle } from '@veneer/core'
import { LoaderContent } from '../LoaderContent'
import { LoaderWrapper, ErrorWrapper, ErrorText } from './styles'
import MicrofrontendType from './type'

const GlobalAny: any = global

const EmptyCard = () => <LoaderWrapper content={<LoaderContent />} />

const ErrorFallback = () => (
  <ErrorWrapper
    appearance="dropShadow"
    content={
      <>
        <IconXCircle
          filled
          size={48}
          color="red6"
        />
        <ErrorText>An error has occurred while loading this widget!</ErrorText>
      </>
    }
  />
)

export const Microfrontend: MicrofrontendType = ({ component, ...props }) => {
  const Card = React.lazy(() => GlobalAny.System.import(component))

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <React.Suspense fallback={<EmptyCard />}>
        <Card {...props} />
      </React.Suspense>
    </ErrorBoundary>
  )
}
