import React, { useCallback } from 'react'
import { MicroFrontend } from '@jarvis/react-mfe-component'
import { getProps } from '../../../utils/commonMethods'

export const GlobalHeader = ({ title }) => {
  const props = getProps()

  const SubMfe = useCallback(
    ({ component, title, type }) => {
      return (
        <MicroFrontend
          disableContainerPadding
          {...props}
          {...{
            component,
            title,
            type
          }}
        />
      )
    },
    [props]
  )

  return (
    <SubMfe
      type="headerTitle"
      title={title}
      component="@jarvis/react-ecp-header"
    />
  )
}
