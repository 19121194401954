/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import testid from '../../utils/testeId'
import { ProgressIndicator } from '@veneer/core'
import { GlobalHeader } from '../shared-components/GlobalHeader/index'
import { getDateandTime, setProps } from '../../utils/commonMethods'
import { getEmail, isServiceCustomer } from '../../utils/api'
import {
  NOTIFICATIONS_ACCESS_PERM,
  DEVICES_ACCESS_PERM,
  DEVICE_POLICY_ACCESS_PERM,
  USERS_ACCESS_PERM,
  ENDPOINT_SECURITY_ACCESS_PERM,
  ACTIVE_USER_RESOURCE_ID
} from '../../utils/constants'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import * as S from './styles'
import {
  ActiveUserType,
  DashBoardIsDisplayed,
  RefreshButtonClicked,
  publishEvent
} from 'src/utils/analytics'

const defaultVisibility = {
  status: true,
  notification: true,
  endpointSecurity: true
}

export const Home = (props) => {
  setProps(props)

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const accessControl = interfaces?.v1?.accessControl || props.mockAccessControl
  const analytics = interfaces?.v1?.analytics || props.mockAnalytics
  const authProvider = interfaces?.v2.authProvider || props.mockAuthProvider
  const authStack = interfaces?.v1?.app?.getAuthStack?.() || props.mockStack
  const breadcrumbs = interfaces?.v1?.breadcrumbs || props.mockBreadcrumbs
  const criterion = interfaces?.v1?.criterion || props.mockCriterion
  const events = interfaces?.v1?.events || props.mockEvents

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()

  const isWideResolution = resolution > 1400

  const {
    status = true,
    notification = true,
    endpointSecurity = true
  } = props.properties?.widgets || defaultVisibility

  const [dateAndTime, setDateAndTime] = useState<string>(getDateandTime())

  //Access control states
  const [notificationsAccessState, setNotificationsAccessState] =
    useState<boolean>(false)
  const [devicesAccessState, setDevicesAccessState] = useState<boolean>(false)
  const [devicePolicyAccessState, setDevicePolicyAccessState] =
    useState<boolean>(false)
  const [usersAccessState, setUsersAccessState] = useState<boolean>(false)
  const [endPtSecurityAccessState, setEndPtSecurityAccessState] =
    useState<boolean>(false)
  const [startingLoader, setStartingLoader] = useState<boolean>(false)

  // Set current user first name
  const [loggedInUser, setLoggedInUser] = useState<string>('')

  const activeUser: ActiveUserType = {
    resourceId: ''
  }

  useEffect(() => {
    const orgedAuthProvider = authProvider?.createOrgedAuthProvider?.()
    const getActiveUserDetails = async () => {
      await getEmail(authStack, orgedAuthProvider).then((result) => {
        setLoggedInUser(result.givenName)
        activeUser.resourceId = result.resourceId
      })
      if (
        window.sessionStorage.getItem(ACTIVE_USER_RESOURCE_ID) !==
        activeUser.resourceId
      ) {
        window.sessionStorage.setItem(
          ACTIVE_USER_RESOURCE_ID,
          activeUser.resourceId
        )
        publishEvent?.(analytics, DashBoardIsDisplayed())
      }
    }
    getActiveUserDetails()
  }, [analytics, authProvider, authStack])

  useEffect(() => {
    setStartingLoader(true)
    accessControl
      .checkScopes(NOTIFICATIONS_ACCESS_PERM)
      ?.then((result) => setNotificationsAccessState(result))
    accessControl
      .checkScopes(DEVICES_ACCESS_PERM)
      ?.then((result) => setDevicesAccessState(result))
    accessControl
      .checkScopes(DEVICE_POLICY_ACCESS_PERM)
      ?.then((result) => setDevicePolicyAccessState(result))
    accessControl
      .checkScopes(USERS_ACCESS_PERM)
      ?.then((result) => setUsersAccessState(result))
    accessControl
      .checkScopes(ENDPOINT_SECURITY_ACCESS_PERM)
      ?.then((result) => setEndPtSecurityAccessState(result))
    setTimeout(() => {
      setStartingLoader(false)
    }, 2000)
  }, [accessControl])

  const renderStyledLoader = () => {
    return (
      <S.StyledDiv>
        <ProgressIndicator />
      </S.StyledDiv>
    )
  }

  const renderNotificationsWidget = useMemo(() => {
    if (startingLoader) {
      return renderStyledLoader()
    } else if (notification && notificationsAccessState) {
      return (
        <S.FirstRowMfe
          {...props}
          component="@jarvis/react-ecp-notification-widget"
        />
      )
    } else return <S.EmptyDiv />
  }, [
    notification,
    notificationsAccessState,
    props,
    startingLoader,
    dateAndTime
  ])

  const renderStatusWidget = useMemo(() => {
    if (startingLoader) {
      return renderStyledLoader()
    } else if (status && devicesAccessState) {
      return (
        <S.FirstRowMfe
          {...props}
          component="@jarvis/react-ecp-status-widget"
        />
      )
    } else return <S.EmptyDiv />
  }, [devicesAccessState, props, startingLoader, status, dateAndTime])

  const renderDeviceStatusWidget = useMemo(() => {
    if (startingLoader) {
      return renderStyledLoader()
    } else if (devicesAccessState) {
      return (
        <S.FirstRowMfe
          {...props}
          component="@jarvis/react-ecp-device-status-widget"
        />
      )
    } else return <S.EmptyDiv />
  }, [devicesAccessState, props, startingLoader, dateAndTime])

  const renderDevicePolicyStatusWidget = useMemo(() => {
    if (startingLoader) {
      return renderStyledLoader()
    } else if (devicePolicyAccessState) {
      return (
        <S.FirstRowMfe
          {...props}
          resolution={resolution}
          component="@jarvis/react-ecp-device-policy-status-widget"
        />
      )
    } else return <S.EmptyDiv />
  }, [devicePolicyAccessState, props, startingLoader, dateAndTime, resolution])

  const renderCustomersUsersStatusWidget = useMemo(() => {
    if (startingLoader) {
      return renderStyledLoader()
    } else if (usersAccessState) {
      return (
        <S.FirstRowMfe
          {...props}
          component="@jarvis/react-ecp-customers-users-status-widget"
        />
      )
    } else return <S.EmptyDiv />
  }, [props, startingLoader, usersAccessState, dateAndTime])

  const renderSecureFleetWidget = useMemo(() => {
    if (startingLoader) {
      return renderStyledLoader()
    } else if (endpointSecurity && endPtSecurityAccessState) {
      return (
        <S.Mfe
          {...props}
          component="@jarvis/react-ecp-endpoint-security-widget"
        />
      )
    } else return <S.EmptyDiv />
  }, [
    endPtSecurityAccessState,
    endpointSecurity,
    props,
    startingLoader,
    dateAndTime
  ])

  const renderSolutionsWidget = useMemo(() => {
    return (
      <S.FirstRowMfe
        {...props}
        component="@jarvis/react-ecp-solutions-and-services-widget"
      />
    )
  }, [props, dateAndTime])

  const renderPrimaryWidgetsWide = useMemo(() => {
    return (
      <>
        <S.Grid
          leftChildFrWidth={8}
          rightChildFrWidth={4}
        >
          {renderNotificationsWidget}
          {renderStatusWidget}
        </S.Grid>
        <S.Grid
          leftChildFrWidth={4}
          middleChildFrWidth={4}
          rightChildFrWidth={4}
        >
          {renderDeviceStatusWidget}
          {renderDevicePolicyStatusWidget}
          {renderCustomersUsersStatusWidget}
        </S.Grid>
        <S.Grid
          leftChildFrWidth={8}
          rightChildFrWidth={4}
        >
          {renderSecureFleetWidget}
          {renderSolutionsWidget}
        </S.Grid>
      </>
    )
  }, [
    renderNotificationsWidget,
    renderStatusWidget,
    renderDeviceStatusWidget,
    renderDevicePolicyStatusWidget,
    renderCustomersUsersStatusWidget,
    renderSecureFleetWidget,
    renderSolutionsWidget
  ])

  const renderPrimaryWidgets = useMemo(() => {
    return (
      <>
        <S.Grid>{renderNotificationsWidget}</S.Grid>
        <S.Grid
          leftChildFrWidth={6}
          rightChildFrWidth={6}
        >
          {renderStatusWidget}
          {renderDeviceStatusWidget}
        </S.Grid>
        <S.Grid
          leftChildFrWidth={6}
          rightChildFrWidth={6}
        >
          {renderDevicePolicyStatusWidget}
          {renderCustomersUsersStatusWidget}
        </S.Grid>
        <S.Grid>{renderSecureFleetWidget}</S.Grid>
        <S.Grid
          leftChildFrWidth={6}
          rightChildFrWidth={6}
        >
          {renderSolutionsWidget}
        </S.Grid>
      </>
    )
  }, [
    renderNotificationsWidget,
    renderStatusWidget,
    renderDeviceStatusWidget,
    renderDevicePolicyStatusWidget,
    renderCustomersUsersStatusWidget,
    renderSecureFleetWidget,
    renderSolutionsWidget
  ])

  const [pageTitle, setPageTitle] = useState<string>()
  const [serviceCustomer, setServiceCustomer] = useState<boolean>(false)

  useEffect(() => {
    const getServiceCustomer = async () => {
      const response = await isServiceCustomer(criterion)
      setServiceCustomer(response)
    }
    getServiceCustomer()
    if (props.properties.useOrgedAuthProvider) {
      serviceCustomer === true
        ? setPageTitle(`${loggedInUser}`)
        : setPageTitle(
            `${t('ecp-dashboard.welcome', 'Welcome ,')}, ${loggedInUser}!`
          )
    } else {
      setPageTitle(' ')
      breadcrumbs.add({
        key: 'customer-dashboard-next-level',
        text: t('ecp-dashboard.dashboard', 'Dashboard'),
        translationKey: undefined,
        url: undefined
      })
    }
  }, [
    props.properties.useOrgedAuthProvider,
    loggedInUser,
    serviceCustomer,
    t,
    breadcrumbs,
    criterion
  ])

  const refreshData = useCallback(() => {
    setDateAndTime(getDateandTime())
    publishEvent?.(analytics, RefreshButtonClicked())
    return dateAndTime
  }, [dateAndTime])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', refreshData)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', refreshData)
    }
  }, [events])

  return (
    <>
      <S.StyledGlobalHeader>
        <GlobalHeader title={pageTitle} />
      </S.StyledGlobalHeader>
      <S.Container data-testid={testid('dashboard')}>
        {isWideResolution ? renderPrimaryWidgetsWide : renderPrimaryWidgets}
      </S.Container>
    </>
  )
}

export default Home
