// Scopes
export const NOTIFICATIONS_ACCESS_PERM = [
  { scope: 'ws-hp.com/activitylog/messages.READ' }
]
export const DEVICES_ACCESS_PERM = [
  { scope: 'ws-hp.com/devicecache/devices.READ' },
  { scope: 'ws-hp.com/devicecache/devices.LIST' }
]
export const DEVICE_POLICY_ACCESS_PERM = [
  { scope: 'ws-hp.com/fleet/task.READ' }
]
export const USERS_ACCESS_PERM = [{ scope: 'ws-hp.com/usermgtsvc/users.LIST' }]
export const CUSTOMERS_ACCESS_PERM = [
  { scope: 'ws-hp.com/tnt-resource-registry/administer.LIST' },
  { scope: 'ws-hp.com/tnt-resource-registry/cross-use.LIST' }
]
export const SOLUTIONS_ENTITLED_ACCESS_PERM = [{ scope: '' }] // currently not having any scopes
export const ENDPOINT_SECURITY_ACCESS_PERM = [
  { scope: 'ws-hp.com/smcloud/fleetassessment.READ' }
]

// Shell analytics constants
export const EVENT_SCREEN_PATH = '/EcpDashboard/'
export const EVENT_ACTIVITY = 'Dashboard-v01'
export const EVENT_DETAIL_TYPE =
  'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'
export const EVENT_CATEGORY = 'simpleUi'
export const EVENT_DETAIL_VERSION = '1.1.0'
export const EVENT_VERSION = '1.4.0'
export const EVENT_ACTION_SCREEN_DISPLAYED = 'ScreenDisplayed'
export const EVENT_ACTION_BUTTON_CLICKED = 'ControlButtonClicked'
export const EVENT_SCREEN_NAME = 'Dashboard'
export const EVENT_CONTROL_LOG_IN = 'ActiveLogIn'
export const EVENT_CONTROL_REFRESH_BUTTON_CLICKED = 'RefreshButtonClicked'
export const EVENT_CONTROL_DETAIL_USER_LOGGED_IN = 'UserLoggedIn'
export const EVENT_CONTROL_DETAIL_USER_CLICKED_REFRESH = 'UserClickedRefresh'

// Session storage constants
export const ACTIVE_USER_RESOURCE_ID = 'activeUserResourceId'
