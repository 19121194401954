import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Microfrontend } from '../Microfrontend'
import {
  createValue,
  exitFunction,
  createPercentage,
  createColumnWidth
} from './styleMethods'

export type GridPropsType = {
  leftChildFrWidth?: number
  middleChildFrWidth?: number
  rightChildFrWidth?: number
}

function gridContentWidth({
  leftChildFrWidth,
  middleChildFrWidth,
  rightChildFrWidth
}: GridPropsType) {
  const getValue = (data) => {
    const value = createValue(data)
    exitFunction(value)
    return createPercentage(value)
  }

  let firstColumn = getValue(leftChildFrWidth)
  let secondColumn = getValue(rightChildFrWidth)
  let thirdColumn = undefined

  if (middleChildFrWidth) {
    firstColumn = getValue(leftChildFrWidth)
    secondColumn = getValue(middleChildFrWidth)
    thirdColumn = getValue(rightChildFrWidth)
  }

  return createColumnWidth(firstColumn, secondColumn, thirdColumn)
}

export const Container = styled.div`
  position: relative;
  top: -70px;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 ${primitives.layout.size6}px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-size: 100% 70px;
  background-repeat: no-repeat;
`

export const Mfe = styled(Microfrontend)`
  && {
    border-radius: ${primitives.layout.cornerRadius4}px;
  }
`

export const FirstRowMfe = styled(Mfe)`
  && > *:first-child {
    box-sizing: border-box;
    height: 56px;
  }

  && {
    height: 403px;
    max-height: 403px;
  }
`

export const Grid = styled.div<GridPropsType>`
  box-sizing: border-box;
  max-width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 577px) {
    display: grid;
    display: -ms-grid;
    grid-template-columns: ${gridContentWidth};
    -ms-grid-columns: ${gridContentWidth};
  }
`

export const ReloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: ${primitives.typography.lineHeight6};
  margin-bottom: -${primitives.layout.size4}px;
  color: #fff;
  > div:first-child {
    font-size: ${primitives.typography.size6};
    line-height: ${primitives.typography.lineHeight6};
  }
`

export const ReloadText = styled.span`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  text-align: left;
`

export const StyledDiv = styled.div`
  width: 100%;
  height: 360px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
  box-shadow: rgb(33 33 33 / 10%) 0px 4px 16px;
  text-align: center;
  font-size: ${primitives.typography.size5};
  display: table;
  span {
    display: table-cell;
    vertical-align: middle;
  }
  > div {
    display: table-cell;
    vertical-align: middle;
    > div[role='progressbar'] {
      margin: auto;
    }
  }
`

export const EmptyDiv = styled.div`
  width: 100%;
  height: 360px;
  background: transparent;
  border: none;
`

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > div:first-child + div {
    width: 100%;
    padding-top: 41px;
    background: #fff;
    border-radius: 0 0 ${primitives.layout.cornerRadius3}px
      ${primitives.layout.cornerRadius3}px;
  }
  div#assessed-passed-widget,
  div#assessed-risks-widget,
  div#not-assessed-widget {
    .highcharts-xaxis-labels {
      text {
        color: #027aae !important;
        fill: #027aae !important;
      }
      text:hover {
        color: #027aae !important;
        fill: #027aae !important;
        cursor: pointer !important;
        text-decoration: underline !important;
      }
    }
  }
  div#assessed-passed-widget > div:first-child,
  div#assessed-risks-widget > div:first-child,
  div#not-assessed-widget > div:first-child {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }
`

export const TitleDiv = styled.div`
  height: 56px;
  width: 100%;
  font-weight: bold;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.1);
  border-radius: ${primitives.layout.cornerRadius3}px
    ${primitives.layout.cornerRadius3}px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TitleLeadingArea = styled.div`
  display: flex;
  > span:first-child {
    margin: 14px ${primitives.layout.size5}px;
    line-height: ${primitives.typography.lineHeight4};
  }
  > span:first-child + div {
    margin-top: 10px;
  }
`

export const TitleTrailingArea = styled.div`
  display: flex;
  > span:first-child {
    margin: 14px 10px;
    line-height: ${primitives.typography.lineHeight4};
  }
  > span:first-child + div,
  > span:first-child + div + div {
    margin-top: 10px;
  }
  > span:first-child + div {
    margin-right: 10px;
  }
  > span:first-child + div + div {
    margin-right: ${primitives.layout.size5}px;
  }
`

export const StyledGlobalHeader = styled.div`
  .tenantStyle,
  .orgStyle {
    height: 107px !important;
  }
`
