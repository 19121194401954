import styled from 'styled-components'
import { Card } from '@veneer/core'

export const LoaderWrapper = styled(Card)`
  width: 100%;
  height: 100%;
`

export const ErrorWrapper = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ErrorText = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props?.theme?.main?.color?.red?.color};
  text-align: center;
  max-width: 250px;
  margin-top: 16px;
`
