/* eslint-disable @typescript-eslint/no-explicit-any */

import { getProps } from './commonMethods'
import getUserProfileMock from '../mocks/get-user-profile.json'
import { UserMgtSvcClientv3 } from '@jarvis/web-stratus-client'

export const getEmail = async (authStack, authProvider): Promise<any> => {
  const props = getProps()
  if (props.mock) {
    return getUserProfileMock
  }
  const userMgtClient = new UserMgtSvcClientv3(authStack, authProvider)
  const activeUser = await userMgtClient.getCurrentActiveUser()
  const data = activeUser.data
  return data
}

export const isServiceCustomer = async (criterion) => {
  const response = await criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  })
  return response
}
