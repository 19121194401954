import React from 'react'
import { Loader } from '../Loader'
import { Container } from './styles'
import LoaderContentProps from './type'

export const LoaderContent: React.FC<LoaderContentProps> = ({ fullScreen }) => (
  <Container>
    <Loader fullScreen={fullScreen} />
  </Container>
)
