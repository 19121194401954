export const createValue = (data) => {
  const thisValue = (() => {
    if (typeof data === 'string') {
      return parseInt(data)
    } else {
      return data
    }
  })()
  if (!isNaN(thisValue)) {
    return data <= 0 ? 0 : data >= 12 ? 12 : data
  } else {
    return false
  }
}

export const exitFunction = (value) => {
  if (typeof value !== 'number') {
    return false
  }
}

export const createPercentage = (value) => {
  if (value === 12) {
    return '100%'
  } else if (value === 0) {
    return '0%'
  } else {
    return `calc((((100% - ${30 * 11}px) / 12) * ${value}) + ${
      30 * (value - 1)
    }px)`
  }
}

export const createColumnWidth = (firstColumn, secondColumn, thirdColumn) => {
  if (firstColumn || secondColumn) {
    if (thirdColumn) {
      return `${firstColumn} ${secondColumn} ${thirdColumn}`
    }
    return `${firstColumn} ${secondColumn}`
  } else {
    return '1fr'
  }
}
