let localprops = {
  language: 'en',
  country: 'US',
  mock: false,
  events: null,
  properties: {
    useOrgedAuthProvider: null
  }
}

export const setProps = (props) => {
  localprops = {
    ...localprops,
    ...props
  }
}

export const getProps = () => {
  return localprops
}

export const getDateandTime = () => {
  const today = new Date()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const month = months[today.getMonth()].substring(0, 3)
  const locale = 'en'
  const dateAndTime = `${month}${'\xa0'}${today.getDate()},
                ${today.toLocaleDateString(locale, {
                  year: 'numeric'
                })}${'\xa0'}${today.toLocaleTimeString(locale, {
                  hour: '2-digit',
                  hour12: true,
                  minute: '2-digit',
                  second: '2-digit'
                })}`
  return dateAndTime
}
